import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../../models/config/config.model';
 

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public get config(): Config {
    return this._config;
  }

  private _config: Config = new Config();

  constructor(private readonly http: HttpClient) {}

  public load(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.http
        .get<Config>('assets/_primeng/config/config.json')
        .subscribe((response) => {
          this._config = response;
          resolve();
        });
    });
  }
}
