import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, EMPTY, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfigService } from "../_primeng/services/appconfig.service";
import { HttpHeaderConstants } from "../constants/http-header.constants";
import { Router } from "@angular/router";

type AuthModel = {
  id: string;
  token: string;
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly configService: AppConfigService
  ) {}

  private authSessionStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken: AuthModel = JSON.parse(
      sessionStorage.getItem(this.authSessionStorageToken)
    );

    const config = this.configService.getConfig();
    this.router.navigate;

    const device = navigator.userAgent;

    let cloneReq = req;

    if (authToken && (config?.apiUrl || req.url.startsWith(config?.apiUrl))) {
      cloneReq = req.clone({
        headers: req.headers
          .set("Authorization", `Bearer ${authToken.token}`)
          .append(HttpHeaderConstants.Device, device),
      });
    } else if (authToken) {
      cloneReq = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${authToken.token}`),
      });
    } else if (config?.apiUrl || req.url.startsWith(config?.apiUrl)) {
      cloneReq = req.clone({
        headers: req.headers.append(HttpHeaderConstants.Device, device),
      });
    }

    return next.handle(cloneReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.router.url !== "/auth/login") {
          sessionStorage.removeItem(this.authSessionStorageToken);
          this.router.navigate(["/auth/login"]).then(() => {
            window.location.reload();
          });
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
