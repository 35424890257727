// France
export const locale = {
  lang: "fr",
  data: {
    COMMON: {
      CANCEL: "Annuler",
      CLOSE: "Fermer",
      SUBMIT: "Soumettre",
      NO: "Non",
      YES: "Oui",
      YES_CLOSE_AND_LOSE_CHANGES: "Oui, fermer et perdre les modifications",
      DISCARD_AND_CLOSE: "Jeter et fermer",
      SAVE_AND_CLOSE: "Enregistrer et fermer",
      SEARCH: "Rechercher...",
      SHOWING_PAGE: "Affichage de la page",
      OF: "de",
      RESULTS_PER_PAGE: "Résultats par page",
      VALIDATION: {
        SOMETHING_WENT_WRONG:
          "Oups, quelque chose a mal tourné ! Veuillez réessayer.",
        SUCCESSFULLY_UPDATED: "Mis à jour avec succès",
        ANY_CHANGES_MADE_WILL_BE_LOST:
          "Toute modification apportée sera perdue.",
        ARE_YOU_SURE_YOU_WANT_TO_PROCEED:
          "Êtes-vous sûr de vouloir continuer ?",
        FAILED_TO_RETRIEVE_DATA:
          "Impossible de récupérer les données du serveur",
        FAILED_TO_RETRIEVE_DATA_FOR_SELECTION:
          "Impossible de récupérer les données pour la sélection",
      },
    },
    NAVBAR: {
      HELLO: "Bonjour",
      MY_DETAILS: "Mes informations",
      MY_CONTACT_METHODS: "Mes méthodes de contact",
      MY_DATA_CONSENTS: "Mes consentements de données",
      MY_DOCUMENTS: "Mes documents",
      LOGOUT: "Déconnexion",
      SELECT_LANGUAGE: "Choisir la langue",
      MENU: "Menu",
      CLOSE: "Fermer",
      SETTINGS: "Paramètres",
      TERMSANDCONDITIONS: "Termes et conditions",
      LANGUAGE: {
        ENLABEL: "Anglais",
        CHLABEL: "Chinois",
        ESLABEL: "Espagnol",
        JALABEL: "Japonais",
        DELABEL: "Allemand",
        FRLABEL: "Français",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "Mon poste actuel",
        COMPLIANCE: "Ma conformité",
        TIMESHEETS: "Mes feuilles de temps",
        EXPENSES: "Mes dépenses",
        DOCUMENTS: "Mes documents",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Se connecter",
        },
        LABEL: {
          EMAIL: "Email",
          PASSWORD: "Mot de passe",
          FORGOT_PASSWORD: "Mot de passe oublié ?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Adresse e-mail invalide",
          EMAIL_MAX_LENGTH:
            "L'e-mail ne doit pas dépasser {{value}} caractères",
          EMAIL_REQUIRED: "L'e-mail est requis",
          LOGIN_FAILED: "Les détails de connexion sont incorrects",
          PASSWORD_MAX_LENGTH:
            "Le mot de passe ne doit pas dépasser {{value}} caractères",
          PASSWORD_MIN_LENGTH:
            "Le mot de passe doit comporter au moins {{value}} caractères",
          PASSWORD_REQUIRED: "Le mot de passe est requis",
        },
      },
      FORGOT_PASSWORD: {
        ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD:
          "Entrez votre e-mail pour réinitialiser votre mot de passe",
        FORGOTTEN_PASSWORD: "Mot de passe oublié ?",
        BUTTON: {
          OK_GOT_IT: "Ok, compris !",
        },
        LABEL: {
          EMAIL: "Email",
          EMAIL_SENT_MESSAGE1:
            "Nous avons envoyé un e-mail à l'adresse que vous avez spécifiée pour réinitialiser votre mot de passe.",
          EMAIL_SENT_MESSAGE2:
            "Si vous ne l'avez pas reçu, veuillez vérifier votre dossier de spam et également vérifier que l'adresse e-mail saisie était correcte.",
          EMAIL_SENT_TITLE: "E-mail de récupération envoyé !",
          SUCCESS: "Votre compte a été réinitialisé avec succès.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Adresse e-mail invalide",
          EMAIL_MAX_LENGTH:
            "L'e-mail ne doit pas dépasser {{value}} caractères",
          EMAIL_REQUIRED: "L'e-mail est requis",
        },
      },
      RESET_PASSWORD: {
        ENTER_NEW_PASSWORD_DETAILS:
          "Entrez les détails du nouveau mot de passe",
        RESET_PASSWORD: "Réinitialiser le mot de passe",
        BUTTON: {},
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Confirmer le nouveau mot de passe",
          CREATE_NEW_PASSWORD: "Créer un nouveau mot de passe",
          OLD_PASSWORD: "Ancien mot de passe",
          PASSWORD: "Mot de passe",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH:
            "'Mot de passe' et 'Confirmer le mot de passe' ne correspondent pas",
          PASSWORD_MAX_LENGTH:
            "Le mot de passe ne doit pas dépasser {{value}} caractères",
          PASSWORD_MIN_LENGTH:
            "Le mot de passe doit comporter au moins {{value}} caractères",
          PASSWORD_PATTERN:
            "Doit contenir des majuscules et minuscules, un chiffre et l'un des suivants : -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Le mot de passe est requis",
          REGISTRATION_DETAILS_INCORRECT:
            "Les détails de l'inscription sont incorrects",
        },
      },
    },
    MY_CONTACT_METHODS: {
      MY_CONTACT_METHODS: "Mes méthodes de contact",
      CONTACT_PREFERENCES: "Préférences de contact",
      PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED:
        "Veuillez sélectionner comment vous souhaitez être contacté.",
      BUTTON: {},
      LABEL: {
        RECEIVE: "Recevoir",
        DO_NOT_RECEIVE: "Ne pas recevoir",
        SALES_EMAIL: "Email de vente",
        EMAIL: "Email",
        PHONE_CALL: "Appel téléphonique",
        TEXT_MESSAGE: "Message texte",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Une erreur s'est produite lors de la mise à jour des préférences de contact",
      },
      WARNING: {
        WARNING_MESSAGE: "Cela désactivera tous les e-mails, y compris ceux pour les feuilles de temps et les dépenses."
      }
    },
    MY_DETAILS: {
      MY_DETAILS: "Mes Détails",
      TAB_TITLES: {
        CANDIDATE_INFORMATION: "Informations sur le Candidat",
        PREFERENCES: "Préférences",
        PROFILE: "Profil",
      },
      CANDIDATE_INFORMATION_TAB: {
        FIRST_NAME: "Prénom",
        LAST_NAME: "Nom de Famille",
        MOBILE_PHONE: "Téléphone Portable",
        HOME_PHONE: "Téléphone Fixe",
        WORK_PHONE: "Téléphone de Travail",
        PERSONAL_EMAIL: "Email Personnel",
        WORK_EMAIL: "Email Professionnel",
        LINKEDIN: "LinkedIn",
        ADDRESSLINE1: "Adresse Ligne 1",
        CITY_TOWN: "Ville",
        POSTCODE: "Code Postal",
        COUNTRY: "Pays",
      },
      PREFERENCES_TAB: {
        AVAILABILITY: "Disponibilité",
        NOTICE_PERIOD: "Période de Préavis",
        CURRENCY: "Devise",
        CONTRACTOR: "Contractuel",
        TEMP: "Intérimaire",
        RATE_FROM: "Taux à Partir de",
        PAY_FREQUENCY: "Fréquence de Paiement",
        RATE_TO: "Taux Jusqu'à",
        PERMANENT: "Permanent",
        SALARY_FROM: "Salaire à Partir de",
        SALARY_TO: "Salaire Jusqu'à",
        CHOOSE_INTERVAL: "Choisir un intervalle...",
        SELECT_A_CURRENCY: "Sélectionner une monnaie...",
        PAY_FREQUENCY_PLACEHOLDER: "Sélectionner une fréquence de paiement...",
      },
      PROFILE_TAB: {
        CANDIDATE_SUMMARY: "Résumé du Candidat",
        ROLES_SOUGHT: "Rôles Recherchés",
        CANDIDATE_SUMMARY_PLACEHOLDER:
          "Veuillez entrer un résumé du candidat...",
        ROLESSOUGHT_SUMMARY_PLACEHOLDER:
          "Veuillez entrer un résumé du rôle recherché par le candidat...",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Une erreur s'est produite lors de la mise à jour de vos informations",
        FIRST_NAME_VALIDATION: "Le prénom est un champ obligatoire",
        LAST_NAME_VALIDATION: "Le nom de famille est un champ obligatoire",
        PHONE_NUMBER_VALIDATION:
          "Veuillez entrer un numéro de téléphone valide",
        PHONE_NUMBER_REQUIRED: "Le téléphone portable est requis",
        EMAIL_VALIDATION: "Veuillez entrer une adresse e-mail valide",
        EMAIL_REQUIRED: "L'e-mail personnel est requis",
        FREQUENCY_REQUIRED: "La fréquence est requise",
        RATE_FROM_VALIDATION:
          "Veuillez vous assurer que le 'Taux maximum' est supérieur ou égal au 'Taux minimum'",
        CANDIDATE_SUMMARY_VALIDATION:
          "Le nombre maximum de caractères autorisé est de 5 000",
        ROLE_SOUGHT_VALIDATION:
          "Le nombre maximum de caractères autorisé est de 2 000",
        SALARY_TO_VALIDATION:
          "Veuillez vous assurer que le 'Salaire maximum' est supérieur ou égal au 'Salaire minimum'",
        SALARY_REQUIRED: "Salaire requis",
        PAY_FREQUENCY_REQUIRED: "Fréquence de paiement requise",
        SELECT_A_UNIT: "Veuillez sélectionner une unité",
        RATE_SET_REQUIRED: "Requis si un taux est défini",
        SALARY_SET_REQUIRED: "Requis si un salaire est défini",
        NOTICE_PERIOD_VALIDATION:
          "La période de préavis ne doit pas être inférieure à 0",
      },
    },
    MY_DATA_CONSENTS: {
      MY_DATA_CONSENTS: "Mes Consentements de Données",
      PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS:
        "Veuillez sélectionner comment vous souhaitez gérer vos consentements de données.",
      BUTTON: {},
      LABEL: {
        CONSENT: "Consentement",
        WITHDRAW: "Retirer",
        DATA_CONSENT_PURPOSE: "Objet du Consentement de Données",
        CONSENT_METHOD: "Méthode de Consentement",
        CONSENT_GIVEN: "Consentement Donné",
        CONSENT_EXPIRY: "Expiration du Consentement",
        WITHDRAW_CONSENT: "Retirer le Consentement",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Une erreur s'est produite lors de la mise à jour de mes consentements de données",
      },
    },
    MY_CURRENT_PLACEMENT: {
      MY_CURRENT_PLACEMENTS: "Mes placements actuels",
      BUTTON: {},
      LABEL: {
        NO_ACTIVE_PLACEMENTS: "Aucun placement actif",
        CLIENT: "Client",
        PLACEMENT_DETAILS: "Détails du placement",
        DOCUMENTS: "Documents",
        JOB_TITLE: "Intitulé du poste",
        TYPE: "Type",
        START_DATE: "Date de début",
        END_DATE: "Date de fin",
        CANDIDATE_COMPANY: "Société du candidat",
        NOTICE_FROM_US: "Avis de notre part",
        NOTICE_FROM_YOU: "Avis de votre part",
        PAY: "Rémunération",
        CLIENT_ADDRESS: "Adresse du client",
        COMPLIANCE: "Conformité",
        STATUS: "Statut",
        EVIDENCE: "Preuve",
        REASON_FOR_REJECTION: "Raison du rejet",
        VALID_FROM: "Valide à partir de",
        VALID_TO: "Valide jusqu'à",
        DESCRIPTION: "Description",
        LAST_UPDATED: "Dernière mise à jour",
        DOCUMENT: "Document",
        PLEASE_CLICK_TO_DOWNLOAD: "Veuillez cliquer pour télécharger",
        DOWNLOAD: "Télécharger",
        NO_DOCUMENTS_FOUND: "Aucun document trouvé",
        CHECKED_ON: "Vérifié le",
        CHECKED_BY: "Vérifié par",
        OUTSTANDING: "En attente",
        SUBMITTED: "Soumis",
        CHECKED: "Vérifié",
        REJECTED: "Rejeté",
      },
      VALIDATION: {
        FROM_DATE_IS_REQUIRED: "La date de début est requise",
        TO_DATE_IS_REQUIRED: "La date de fin est requise",
        TO_DATE_LESS_THAN_FROM_DATE:
          "La date de fin doit être postérieure à la date de début",
        FROM_DATE_GREATER_THAN_TO_DATE:
          "La date de début ne peut pas être postérieure à la date de fin",
        ERROR_SAVING: "Erreur lors de l'enregistrement de la conformité",
        TO_DATE_MUST_BE_IN_FUTURE:
          "La date de fin doit être définie dans le futur",
      },
    },
    FILEUPLOAD: {
      CHOOSEFILE: "Choisir un fichier",
      UPLOADFILE: "Télécharger un fichier",
      REMOVEFILE: "Supprimer le fichier",
      PENDINGUPLOAD: "Téléchargement en attente",
      COMPLETEDUPLOAD: "Téléchargement terminé",
      COMPLETED: "Terminé",
      VALIDATION: {
        INVALID_FILE: "Fichier sélectionné invalide",
        FILE_IS_ZERO_SIZE:
          "La taille du fichier ne peut pas être nulle. Veuillez sélectionner un fichier valide et réessayer.",
        MULTIPLE_FILES_ARE_ZERO_SIZE:
          "Les tailles de fichiers ne peuvent pas être nulles. Les fichiers invalides seront supprimés. Veuillez vous assurer que tous les fichiers sélectionnés sont valides et réessayez.",
      },
    },
    DOCUSIGN: {
      DOCUSIGN_TITLE: "DocuSign",
      LABEL: {
        COMPLETED: "Terminé",
        SENT: "Envoyé",
        DELIVERED: "Livré",
        VOIDED: "Annulé",
        CREATED: "Créé",
        STATUS: "Statut",
        ENVELOPEID: "ID de l'enveloppe",
        PLACEMENT: "Placement",
        CREATED_DATE: "Date de création",
        NAME: "Nom",
        TYPE: "Type",
        DOCUMENTID: "Identifiant du document",
        SIGN_DOCUMENTS: "Signer des documents",
        EMBEDDED_URL_CREATED: "URL intégrée créée",
        VIEW_PDF: "Voir le PDF"
      },
    },
  },
};
