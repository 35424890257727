// Japan
export const locale = {
  lang: "ja",
  data: {
    COMMON: {
      CANCEL: "キャンセル",
      CLOSE: "閉じる",
      SUBMIT: "送信",
      NO: "いいえ",
      YES: "はい",
      YES_CLOSE_AND_LOSE_CHANGES: "はい、閉じて変更を破棄します",
      DISCARD_AND_CLOSE: "破棄して閉じる",
      SAVE_AND_CLOSE: "保存して閉じる",
      SEARCH: "検索...",
      SHOWING_PAGE: "ページ表示中",
      OF: "の",
      RESULTS_PER_PAGE: "ページごとの結果",
      VALIDATION: {
        SOMETHING_WENT_WRONG:
          "おっと、何か問題が発生したようです！もう一度お試しください。",
        SUCCESSFULLY_UPDATED: "正常に更新されました",
        ANY_CHANGES_MADE_WILL_BE_LOST: "行われた変更はすべて失われます。",
        ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "続行してもよろしいですか？",
        FAILED_TO_RETRIEVE_DATA: "サーバーからデータを取得できませんでした",
        FAILED_TO_RETRIEVE_DATA_FOR_SELECTION:
          "選択のためのデータを取得できませんでした",
      },
    },
    NAVBAR: {
      HELLO: "こんにちは",
      MY_DETAILS: "私の詳細",
      MY_CONTACT_METHODS: "私の連絡方法",
      MY_DATA_CONSENTS: "私のデータ同意",
      MY_DOCUMENTS: "マイドキュメント",
      LOGOUT: "ログアウト",
      SELECT_LANGUAGE: "言語を選択",
      MENU: "メニュー",
      CLOSE: "閉じる",
      SETTINGS: "設定",
      TERMSANDCONDITIONS: "利用規約",
      LANGUAGE: {
        ENLABEL: "英語",
        CHLABEL: "中国語",
        ESLABEL: "スペイン語",
        JALABEL: "日本語",
        DELABEL: "ドイツ語",
        FRLABEL: "フランス語",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "現在の配置",
        COMPLIANCE: "コンプライアンス",
        TIMESHEETS: "タイムシート",
        EXPENSES: "経費",
        DOCUMENTS: "マイドキュメント",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "サインイン",
        },
        LABEL: {
          EMAIL: "メール",
          PASSWORD: "パスワード",
          FORGOT_PASSWORD: "パスワードを忘れましたか？",
        },
        VALIDATION: {
          EMAIL_INVALID: "無効なメールアドレスです",
          EMAIL_MAX_LENGTH: "メールは{{value}}文字を超えてはなりません",
          EMAIL_REQUIRED: "メールは必須です",
          LOGIN_FAILED: "ログイン情報が正しくありません",
          PASSWORD_MAX_LENGTH: "パスワードは{{value}}文字を超えてはなりません",
          PASSWORD_MIN_LENGTH:
            "パスワードは最低{{value}}文字でなければなりません",
          PASSWORD_REQUIRED: "パスワードは必須です",
        },
      },
      FORGOT_PASSWORD: {
        ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD:
          "パスワードをリセットするためにメールアドレスを入力してください",
        FORGOTTEN_PASSWORD: "パスワードを忘れましたか？",
        BUTTON: {
          OK_GOT_IT: "わかりました！",
        },
        LABEL: {
          EMAIL: "メール",
          EMAIL_SENT_MESSAGE1:
            "パスワードをリセットするためのメールを指定されたアドレスに送信しました。",
          EMAIL_SENT_MESSAGE2:
            "受信していない場合は、迷惑メールフォルダーを確認し、入力されたメールアドレスが正しいことを確認してください。",
          EMAIL_SENT_TITLE: "回復メールを送信しました！",
          SUCCESS: "アカウントが正常にリセットされました。",
        },
        VALIDATION: {
          EMAIL_INVALID: "無効なメールアドレスです",
          EMAIL_MAX_LENGTH: "メールは{{value}}文字を超えてはなりません",
          EMAIL_REQUIRED: "メールは必須です",
        },
      },
      RESET_PASSWORD: {
        ENTER_NEW_PASSWORD_DETAILS: "新しいパスワードの詳細を入力してください",
        RESET_PASSWORD: "パスワードをリセット",
        BUTTON: {},
        LABEL: {
          CONFIRM_NEW_PASSWORD: "新しいパスワードを確認",
          CREATE_NEW_PASSWORD: "新しいパスワードを作成",
          OLD_PASSWORD: "古いパスワード",
          PASSWORD: "パスワード",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH:
            "「パスワード」と「パスワードの確認」が一致しません",
          PASSWORD_MAX_LENGTH: "パスワードは{{value}}文字を超えてはなりません",
          PASSWORD_MIN_LENGTH:
            "パスワードは最低{{value}}文字でなければなりません",
          PASSWORD_PATTERN:
            "大文字と小文字の両方、数字、および以下のいずれかを含む必要があります: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "パスワードは必須です",
          REGISTRATION_DETAILS_INCORRECT: "登録情報が正しくありません",
        },
      },
    },
    MY_CONTACT_METHODS: {
      MY_CONTACT_METHODS: "私の連絡方法",
      CONTACT_PREFERENCES: "連絡の好み",
      PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED:
        "連絡方法を選択してください。",
      BUTTON: {},
      LABEL: {
        RECEIVE: "受け取る",
        DO_NOT_RECEIVE: "受け取らない",
        SALES_EMAIL: "営業メール",
        EMAIL: "メール",
        PHONE_CALL: "電話",
        TEXT_MESSAGE: "テキストメッセージ",
      },
      VALIDATION: {
        ERROR_UPDATING: "連絡の好みを更新中にエラーが発生しました",
      },
      WARNING: {
        WARNING_MESSAGE: "これにより、タイムシートや経費のためのメールを含む、すべてのメールが無効になります。"
      }
    },
    MY_DETAILS: {
      MY_DETAILS: "私の詳細",
      TAB_TITLES: {
        CANDIDATE_INFORMATION: "候補者情報",
        PREFERENCES: "設定",
        PROFILE: "プロフィール",
      },
      CANDIDATE_INFORMATION_TAB: {
        FIRST_NAME: "名",
        LAST_NAME: "姓",
        MOBILE_PHONE: "携帯電話",
        HOME_PHONE: "自宅電話",
        WORK_PHONE: "勤務先電話",
        PERSONAL_EMAIL: "個人メール",
        WORK_EMAIL: "仕事用メール",
        LINKEDIN: "LinkedIn",
        ADDRESSLINE1: "住所1行目",
        CITY_TOWN: "市区町村",
        POSTCODE: "郵便番号",
        COUNTRY: "国",
      },
      PREFERENCES_TAB: {
        AVAILABILITY: "利用可能性",
        NOTICE_PERIOD: "通知期間",
        CURRENCY: "通貨",
        CONTRACTOR: "契約者",
        TEMP: "臨時",
        RATE_FROM: "料金の下限",
        PAY_FREQUENCY: "支払い頻度",
        RATE_TO: "料金の上限",
        PERMANENT: "正社員",
        SALARY_FROM: "給与の下限",
        SALARY_TO: "給与の上限",
        CHOOSE_INTERVAL: "間隔を選択...",
        SELECT_A_CURRENCY: "通貨を選択...",
        PAY_FREQUENCY_PLACEHOLDER: "支払い頻度を選択...",
      },
      PROFILE_TAB: {
        CANDIDATE_SUMMARY: "候補者の概要",
        ROLES_SOUGHT: "希望の役割",
        CANDIDATE_SUMMARY_PLACEHOLDER: "候補者の概要を入力してください...",
        ROLESSOUGHT_SUMMARY_PLACEHOLDER:
          "求める役割の概要を入力してください...",
      },
      VALIDATION: {
        ERROR_UPDATING: "詳細情報の更新中にエラーが発生しました",
        FIRST_NAME_VALIDATION: "名は必須フィールドです",
        LAST_NAME_VALIDATION: "姓は必須フィールドです",
        PHONE_NUMBER_VALIDATION: "有効な電話番号を入力してください",
        PHONE_NUMBER_REQUIRED: "携帯電話が必要です",
        EMAIL_VALIDATION: "有効なメールアドレスを入力してください",
        EMAIL_REQUIRED: "個人用メールは必須です",
        FREQUENCY_REQUIRED: "頻度は必須です",
        RATE_FROM_VALIDATION:
          "「レート最大」が「レート最小」以上であることを確認してください",
        CANDIDATE_SUMMARY_VALIDATION: "許可される最大文字数は5,000です",
        ROLE_SOUGHT_VALIDATION: "許可される最大文字数は2,000です",
        SALARY_TO_VALIDATION:
          "「給与最大」が「給与最小」以上であることを確認してください",
        SALARY_REQUIRED: "給与が必要です",
        PAY_FREQUENCY_REQUIRED: "支払い頻度が必要です",
        SELECT_A_UNIT: "ユニットを選択してください",
        RATE_SET_REQUIRED: "レートが設定されている場合は必須です",
        SALARY_SET_REQUIRED: "給与が設定されている場合は必須です",
        NOTICE_PERIOD_VALIDATION: "通知期間は0未満であってはなりません",
      },
    },
    MY_DATA_CONSENTS: {
      MY_DATA_CONSENTS: "私のデータ同意",
      PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS:
        "データ同意の管理方法を選択してください。",
      BUTTON: {},
      LABEL: {
        CONSENT: "同意",
        WITHDRAW: "撤回",
        DATA_CONSENT_PURPOSE: "データ同意の目的",
        CONSENT_METHOD: "同意方法",
        CONSENT_GIVEN: "同意済み",
        CONSENT_EXPIRY: "同意の有効期限",
        WITHDRAW_CONSENT: "同意を撤回",
      },
      VALIDATION: {
        ERROR_UPDATING: "データ同意の更新中にエラーが発生しました",
      },
    },
    MY_CURRENT_PLACEMENT: {
      MY_CURRENT_PLACEMENTS: "私の現在の配置",
      BUTTON: {},
      LABEL: {
        NO_ACTIVE_PLACEMENTS: "アクティブな配置はありません",
        CLIENT: "クライアント",
        PLACEMENT_DETAILS: "配置の詳細",
        DOCUMENTS: "ドキュメント",
        JOB_TITLE: "職種",
        TYPE: "タイプ",
        START_DATE: "開始日",
        END_DATE: "終了日",
        CANDIDATE_COMPANY: "候補者の会社",
        NOTICE_FROM_US: "こちらからのお知らせ",
        NOTICE_FROM_YOU: "あなたからのお知らせ",
        PAY: "給与",
        CLIENT_ADDRESS: "クライアントの住所",
        COMPLIANCE: "コンプライアンス",
        STATUS: "ステータス",
        EVIDENCE: "証拠",
        REASON_FOR_REJECTION: "拒否理由",
        VALID_FROM: "有効期限開始",
        VALID_TO: "有効期限終了",
        DESCRIPTION: "説明",
        LAST_UPDATED: "最終更新",
        DOCUMENT: "ドキュメント",
        PLEASE_CLICK_TO_DOWNLOAD: "ダウンロードするにはクリックしてください",
        DOWNLOAD: "ダウンロード",
        NO_DOCUMENTS_FOUND: "ドキュメントが見つかりませんでした",
        CHECKED_ON: "チェック日",
        CHECKED_BY: "チェック担当者",
        OUTSTANDING: "未解決",
        SUBMITTED: "提出済み",
        CHECKED: "チェック済み",
        REJECTED: "却下されました",
      },
      VALIDATION: {
        FROM_DATE_IS_REQUIRED: "開始日は必須です",
        TO_DATE_IS_REQUIRED: "終了日は必須です",
        TO_DATE_LESS_THAN_FROM_DATE:
          "終了日は開始日よりも後でなければなりません",
        FROM_DATE_GREATER_THAN_TO_DATE:
          "開始日は終了日よりも後であってはなりません",
        ERROR_SAVING: "コンプライアンスの保存中にエラーが発生しました",
        TO_DATE_MUST_BE_IN_FUTURE: "終了日は未来の日付を設定する必要があります",
      },
    },
    FILEUPLOAD: {
      CHOOSEFILE: "ファイルを選択",
      UPLOADFILE: "ファイルをアップロード",
      REMOVEFILE: "ファイルを削除",
      PENDINGUPLOAD: "アップロード待機中",
      COMPLETEDUPLOAD: "アップロード完了",
      COMPLETED: "完了",
      VALIDATION: {
        INVALID_FILE: "無効なファイルが選択されました",
        FILE_IS_ZERO_SIZE:
          "ファイルサイズはゼロにできません。有効なファイルを選択して再試行してください。",
        MULTIPLE_FILES_ARE_ZERO_SIZE:
          "ファイルサイズはゼロにできません。無効なファイルは削除されます。すべての選択されたファイルが有効であることを確認し、再試行してください。",
      },
    },
    DOCUSIGN: {
      DOCUSIGN_TITLE: "DocuSign",
      LABEL: {
        COMPLETED: "完了",
        SENT: "送信済み",
        DELIVERED: "配信済み",
        VOIDED: "無効",
        CREATED: "作成済み",
        STATUS: "ステータス",
        ENVELOPEID: "封筒ID (ふうとうID)",
        PLACEMENT: "配置",
        CREATED_DATE: "作成日",
        NAME: "名前",
        TYPE: "種類",
        DOCUMENTID: "ドキュメントID",
        SIGN_DOCUMENTS: "ドキュメントに署名",
        EMBEDDED_URL_CREATED: "埋め込みURLが作成されました",
        VIEW_PDF: "PDFを表示",
      },
    },
  },
};
