import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserModel } from '../../models/user.model';
import { DataAccess } from '../../models/dataaccess.model';
import { AuthModel } from '../../models/auth.model';
import { ResetPasswordModel } from '../../models/reset-password.model';
import { Theme } from 'src/app/_primeng/models/theme/theme.model';
import { AuthHTTPService } from './auth-http.service';
import { StorageKeyConstants } from 'src/app/_primeng/constants/storage-key.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSessionStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private readonly DataAccessStorageKey = 'DataAccess';

  public isLoading$: Observable<boolean>;
  public currentUserSubject: BehaviorSubject<UserModel>;
  public isLoadingSubject: BehaviorSubject<boolean>;
  public isResetPassword: boolean;
  public timesheetsEnabled: boolean;
  public userName: string;
  public selectedTheme: BehaviorSubject<string> = new BehaviorSubject<string>("lara-light-blue");

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // * === === === === === === === === === === === === === ===
  // * PRIVATE METHODS
  // * === === === === === === === === === === === === === ===

  private setAuthFromSessionStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in session storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      sessionStorage.setItem(this.authSessionStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromSessionStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        sessionStorage.getItem(this.authSessionStorageToken)
      );

      if (
        !authData?.token &&
        this.router.url !== "/auth/login" &&
        this.router.url !== "/"
      )
        setTimeout(() => {
          this.router.navigate(["/auth/login"]).then(() => {
            window.location.reload();
          });
        }, 500);

      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // * === === === === === === === === === === === === === ===
  // * PUBLIC METHODS
  // * === === === === === === === === === === === === === ===

  public getDataAccess(): Observable<boolean> {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.httpGet("/auth/dataAccess", auth.token).pipe(
      map((dataAccess: DataAccess) => {
        sessionStorage.setItem(this.DataAccessStorageKey, JSON.stringify(dataAccess));
        return true;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public get dataAccessValue(): DataAccess {
    return JSON.parse(sessionStorage.getItem(this.DataAccessStorageKey));
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  public login(email: string, password: string): Observable<UserModel | boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromSessionStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      switchMap(() => this.getDataAccess()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public logout(): void {
    sessionStorage.removeItem(this.authSessionStorageToken);
    sessionStorage.removeItem(this.DataAccessStorageKey);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  public resetPassword(resetPassword: ResetPasswordModel): Observable<boolean> {
    this.isLoadingSubject.next(true);
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService
      .resetPassword(auth.token, resetPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  public forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  public httpGet(url: string, responseType: any = 'json') {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService.httpGet(url, auth.token, responseType);
  }

  

  public getTheme(hash: string): Observable<Theme> {
    return this.authHttpService.getTheme(hash);
  }

  public httpPost(url: string, body: any) {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService.httpPost(url, body, auth.token);
  }

  public httpPut(url: string, body: any, returnHttpResponse: boolean = false) {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService.httpPut(url, body, auth.token, returnHttpResponse);
  }

  public httpDelete(url: string) {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService.httpDelete(url, auth.token);
  }

  public getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserModel) => {
        if (user) {
          if (user.needsPasswordReset) {
            this.isResetPassword = true;
            this.userName = user.username;
            this.router.navigate(['/auth/reset-password']);
          }
          else {
            this.currentUserSubject = new BehaviorSubject<UserModel>(user);
          }
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public setSelectedTheme(theme: string): void {
    this.selectedTheme.next(theme);
    localStorage.setItem(StorageKeyConstants.SelectedTheme, theme);
  }

}
